<template>
  <div class="version-page" v-loading="loading">
    <div class="page-top">
      <Reminder
        text="如果微信审核不过审，可能是设置的小程序页面信息不完整或者微信后台设置的类目与小程序页面信息不匹配；您可以根据微信返回的失败原因，进行调整后，可进行手动提交审核"
      />
      <div class="auto-update" v-if="false">
        <div class="switch-btn">
          <p>自动更新</p>
          <el-switch
            style="margin-left: 15px"
            v-model="versionForm.is_auto_update"
            @change="handleAutoUpdate"
          >
          </el-switch>
        </div>
        <p class="update-tip">
          <span class="el-icon-info"></span>
          开启后，系统将自动将您的小程序更新至最新版本并提交审核（若审核失败需手动更新）
        </p>
      </div>
    </div>
    <div class="version-detail">
      <div style="margin-top: 24px" v-if="versionForm">
        <div
          class="modular"
          v-for="(version, index) in notDevList"
          :style="!version.config ? 'height: 200px' : ''"
        >
          <p class="none-version" v-if="!version.config">
            {{ versionNone[version.type] }}
          </p>
          <p class="modular-title">{{ version.title }}</p>
          <div class="modular-content" v-if="version.config">
            <el-form
              :model="version.config"
              size="medium"
              label-position="left"
              :label-width="
                version.type === 'online_version' ? '100px' : '75px'
              "
              class="form"
            >
              <el-form-item
                v-if="version.config.version"
                :label="version.title + '：'"
              >
                {{ version.config.version }}
              </el-form-item>
              <el-form-item
                v-if="version.config.commit_time"
                label="提交时间："
              >
                {{ version.config.commit_time }}</el-form-item
              >
              <el-form-item v-if="version.config.post_time" label="发布时间：">
                {{ version.config.post_time }}</el-form-item
              >
              <el-form-item
                v-if="version.config.commit_check_time"
                label="提交审核时间："
              >
                {{ version.config.commit_check_time }}</el-form-item
              >
              <el-form-item
                v-if="version.config.template_time"
                label="代码提交时间："
              >
                {{ version.config.template_time }}</el-form-item
              >
              <el-form-item
                :style="{
                  color: !version.config.status
                    ? '#3576FF'
                    : version.config.status === 2
                    ? '#13CE66'
                    : '#FF5350',
                }"
                v-if="version.config.status !== undefined"
                label-width="0"
              >
                {{ version.config.status_text
                }}{{ version.config.reason ? ':' + version.config.reason : '' }}
              </el-form-item>
              <!-- <el-form-item v-if="version.type === 'dev_version'" label="审核加急：">
                <el-radio v-model="isUrgent" :label="0">不加急</el-radio>
                <el-radio v-model="isUrgent" :label="1">加急</el-radio>
                <p class="is-urgent-tip"><span class="el-icon-info"></span>（今年剩余
                  {{SpeedupCheck?SpeedupCheck.rest:'-'}}
                  次，按自然年计算）选择不加急审核，预计在1-7天内完成审核。</p>
              </el-form-item> -->
            </el-form>
            <div class="detail-right-btn">
              <div class="btn-group">
                <!-- <el-button size="small" v-if="version.type === 'online_version'" type="primary"
                  @click="rollBack(version.config.check_id)" :loading="rollBackLoading">版本回退</el-button> -->
                <el-button
                  size="small"
                  v-if="
                    version.type === 'check_version' &&
                    version.config.status === 0
                  "
                  type="primary"
                  @click="UnCommit(version.config.check_id)"
                  :loading="unCommitLoading"
                  >撤回
                </el-button>
                <el-button
                  size="small"
                  v-if="
                    version.type === 'check_version' &&
                    version.config.status === 2
                  "
                  type="primary"
                  @click="postVersion(version.config)"
                  :loading="postLoading"
                  >发布新版
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="modular">
          <div
            class="modular-item"
            :style="{
              paddingBottom: '13px',
              borderBottom: index === devList.length - 1 ? 'none' : '',
              height: !version.config ? '200px' : '',
            }"
            v-for="(version, index) in devList"
          >
            <p class="none-version" v-if="!version.config">
              {{ versionNone[version.type] }}
            </p>
            <p class="modular-title" v-if="index === 0">{{ version.title }}</p>
            <div class="modular-content" v-if="version.config">
              <el-form
                :model="version.config"
                size="medium"
                label-position="right"
                label-width="85px"
                class="form"
              >
                <el-form-item
                  v-if="version.config.version"
                  :label="version.title + '：'"
                >
                  {{ version.config.version }}
                  <el-popover
                    v-if="version.type === 'dev_version'"
                    placement="right-start"
                    title="更新日志"
                    width="400"
                    trigger="click"
                  >
                    <p style="white-space: pre-wrap">
                      {{ version.config.description }}
                    </p>
                    <span class="update-log" slot="reference">更新日志</span>
                  </el-popover>
                </el-form-item>
                <el-form-item
                  v-if="version.config.commit_time"
                  label="提交时间："
                >
                  {{ version.config.commit_time }}</el-form-item
                >
                <el-form-item
                  v-if="version.config.post_time"
                  label="发布时间："
                >
                  {{ version.config.post_time }}</el-form-item
                >
                <el-form-item
                  v-if="version.config.commit_check_time"
                  label="提交审核时间："
                >
                  {{ version.config.commit_check_time }}</el-form-item
                >
                <el-form-item
                  v-if="version.config.template_time"
                  label="代码提交时间："
                >
                  {{ version.config.template_time }}</el-form-item
                >
                <!-- <el-form-item v-if="version.type === 'dev_version'" label="审核加急：">
                <el-radio v-model="isUrgent" :label="0">不加急</el-radio>
                <el-radio v-model="isUrgent" :label="1">加急</el-radio>
                <p class="is-urgent-tip"><span class="el-icon-info"></span>（今年剩余
                  {{SpeedupCheck?SpeedupCheck.rest:'-'}}
                  次，按自然年计算）选择不加急审核，预计在1-7天内完成审核。</p>
              </el-form-item> -->
              </el-form>
              <div class="detail-right-btn">
                <div class="btn-group">
                  <el-button
                    size="small"
                    v-if="version.config.is_dev"
                    type="primary"
                    @click="
                      commitExam(
                        version.config.version_id,
                        version.config.version
                      )
                    "
                    >提交审核</el-button
                  >

                  <el-button
                    size="small"
                    :class="version.config.is_dev ? 'active-btn' : ''"
                    v-if="
                      version.type === 'dev_version' && !version.config.is_dev
                    "
                    @click="
                      handleClick(
                        version.config.version_id,
                        version.config.is_dev
                      )
                    "
                    :loading="
                      setLoading &&
                      currentId === version.config.version_id &&
                      !version.config.is_dev
                    "
                  >
                    <span v-if="!version.config.is_dev">选为体验版</span>
                  </el-button>

                  <el-button
                    slot="reference"
                    size="small"
                    :class="version.config.is_dev ? 'active-btn' : ''"
                    v-if="
                      version.type === 'dev_version' &&
                      version.config.is_dev &&
                      versionForm.design_status === 1
                    "
                    @click="
                      handleClick(
                        version.config.version_id,
                        version.config.is_dev
                      )
                    "
                    :loading="
                      setLoading &&
                      currentId === version.config.version_id &&
                      !version.config.is_dev
                    "
                  >
                    <div v-if="version.config.is_dev" class="btn-layout">
                      已选为体验版<img
                        class="qr-code-btn"
                        v-if="version.config.is_dev"
                        src="../../static/images/qr-code-icon.png"
                      />
                    </div>
                  </el-button>
                  <el-popover
                    style="margin-left: 24px"
                    v-if="
                      version.type === 'dev_version' &&
                      version.config.is_dev &&
                      versionForm.design_status !== 1
                    "
                    placement="top"
                    title="提示"
                    width="200"
                    v-model="pVisible"
                  >
                    <p style="margin-bottom: 10px">请先前往页面装修发布版本</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="text"
                        @click="pVisible = false"
                        >取消</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        @click="
                          handleClick(
                            version.config.version_id,
                            version.config.is_dev
                          )
                        "
                        >前往</el-button
                      >
                    </div>
                    <el-button
                      slot="reference"
                      size="small"
                      :class="version.config.is_dev ? 'active-btn' : ''"
                      v-if="
                        version.type === 'dev_version' && version.config.is_dev
                      "
                      :loading="
                        setLoading &&
                        currentId === version.config.version_id &&
                        !version.config.is_dev
                      "
                    >
                      <div v-if="version.config.is_dev" class="btn-layout">
                        已选为体验版<img
                          class="qr-code-btn"
                          v-if="version.config.is_dev"
                          src="../../static/images/qr-code-icon.png"
                        />
                      </div>
                    </el-button>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExperienceQRCode
      v-if="versionForm"
      v-model="openQrCode"
      :qrCode="versionForm.qrcode"
    />
    <MergeConfigDialog
      @done="getVersionInfo"
      v-model="openConfig"
      :id="currentId"
      :version="currentVersion"
    />
  </div>
</template>

<script>
import {
  versionInfo,
  undoCommit,
  versionPost,
  rollbackVersion,
  AutoUpdate,
  uploadVersion,
  commitVersion,
  checkDevPageConfig,
  syncDevPageConfig,
} from '../../api/version-setting.js'
import ExperienceQRCode from '../../components/ExperienceQRCode.vue'
import Reminder from '@/modules/common/components/Reminder'
import CommitExamDialog from '../../components/CommitExamDialog.vue'
import MergeConfigDialog from '../../components/MergeConfigDialog.vue'

export default {
  components: {
    ExperienceQRCode,
    CommitExamDialog,
    Reminder,
    MergeConfigDialog,
  },
  data() {
    return {
      loading: false,
      versionForm: null,
      currentId: 0,
      currentVersion: '',
      isUrgent: 0,
      versionNone: {
        dev_version: '你暂无提交开发版',
        check_version: '你暂无提交审核的版本或者版本已发布上线',
        online_version: '尚未发布线上版本',
      },
      statusArr: ['审核中', '审核失败', '审核成功', '已撤回', '审核延后'],
      openQrCode: false,
      openCommitExam: false,
      unCommitLoading: false,
      setLoading: false,
      postLoading: false,
      rollBackLoading: false,
      pVisible: false,
      // 是否可以同步到体验版的配置 0 没有， 1有
      isMergeConfig: 0,
      // 同步配置提交审核弹窗
      openConfig: false,
    }
  },
  computed: {
    devList() {
      return this.versionForm
        ? this.versionForm.version_list.filter(
            (item) => item.type === 'dev_version'
          )
        : []
    },
    notDevList() {
      return this.versionForm
        ? this.versionForm.version_list.filter(
            (item) => item.type !== 'dev_version'
          )
        : []
    },
  },
  methods: {
    // 获取版本设置信息
    getVersionInfo() {
      this.loading = true
      versionInfo()
        .then((res) => {
          this.versionForm = res.data
          this.versionForm.is_auto_update = res.data.is_auto_update
            ? true
            : false
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    //设置自动更新
    handleAutoUpdate(value) {
      AutoUpdate()
        .then((res) => {
          this.$message.success(value ? '已开启自动更新' : '已关闭自动更新')
        })
        .catch((err) => {
          this.versionForm.is_auto_update = !value
        })
    },
    // 设为体验版
    handleClick(id, is_dev) {
      if (this.versionForm.design_status !== 1) {
        this.$router.push({ name: 'AppDesignIndex' })
        return
      }
      if (is_dev) {
        this.openQrCode = true
      } else {
        this.currentId = id
        this.setLoading = true
        uploadVersion({ id })
          .then((res) => {
            if (res.data.status === 1) {
              this.$message.success(res.msg)
              this.getVersionInfo()
              this.setLoading = false
            } else if ([2, 4].includes(res.data.status)) {
              this.$msgbox
                .confirm(res.msg, '提示', {
                  confirmButtonText: '前往',
                  type: 'error',
                })
                .then((res) => {
                  window.open('https://mp.weixin.qq.com/', '_blank')
                  this.setLoading = false
                })
                .catch((err) => {
                  this.setLoading = false
                })
            } else if (res.data.status === 3) {
              this.$msgbox
                .confirm(res.msg, '提示', {
                  confirmButtonText: '前往',
                  type: 'error',
                })
                .then((res) => {
                  let routerData = this.$router.resolve({
                    name: 'PluginFeature',
                  })
                  window.open(routerData.href, '_blank')
                  this.setLoading = false
                })
                .catch((err) => {
                  this.setLoading = false
                })
            }
          })
          .catch((err) => {
            this.setLoading = false
          })
      }
    },
    //版本发布
    postVersion({ check_id, commit_wx_check_time }) {
      this.postLoading = true
      let commit_version_id = ''
      // 列表中有“提交审核”按钮的那条数据的 version_id
      this.devList.forEach((version, index) => {
        if (version.config.is_dev) {
          commit_version_id = version.config.version_id
        }
      })

      // 有线上版本的条件：列表中有 online_version，且 config 不为 null
      const exist_online_version = this.notDevList.some(
        (item) => item.type === 'online_version'
      )
        ? !!this.notDevList.find((d) => d.type === 'online_version')?.config
          ? 1
          : 0
        : 0
      console.log(
        check_id,
        commit_wx_check_time,
        commit_version_id,
        exist_online_version
      )
      versionPost({
        id: check_id,
        commit_wx_check_time,
        commit_version_id,
        exist_online_version,
      })
        .then((res) => {
          this.$message.success(res.msg)
          this.postLoading = false
          this.getVersionInfo()
        })
        .catch((err) => {
          this.postLoading = false
        })
    },
    // 提交审核
    commitExam(id, version) {
      this.currentId = id
      this.currentVersion = version
      this.loading = true
      // 直接提交审核
      commitVersion({ is_speedup: 0, id })
        .then((res) => {
          this.$message.success(res.msg)
          this.getVersionInfo()
        })
        .catch((err) => {
          this.loading = false
        })

      // 打开同步配置审核弹窗
      // this.openConfig = true;
      // } else {
      //   this.$msgbox
      //     .confirm(`是否提交当前开发版本：${version} 进行审核`, "提示", {
      //       type: "info",
      //     })
      //     .then((res) => {
      //       this.loading = true;
      //       commitVersion({ is_speedup: 0, id })
      //         .then((res) => {
      //           this.$message.success(res.msg);
      //           this.getVersionInfo();
      //         })
      //         .catch((err) => {
      //           this.loading = false;
      //         });
      //     })
      //     .catch((err) => {});
      // }
    },
    // 撤回审核
    UnCommit(id) {
      // 目前修改成不用传id也可以撤回
      undoCommit()
        .then((res) => {
          this.$message.success(res.msg)
          this.unCommitLoading = false
          this.getVersionInfo()
        })
        .catch((err) => {
          this.unCommitLoading = false
        })
    },
    // 版本回退
    rollBack(id) {
      this.rollBackLoading = true
      rollbackVersion({ id })
        .then((res) => {
          this.$message.success(res.msg)
          this.rollBackLoading = false
          this.getVersionInfo()
        })
        .catch((err) => {
          this.rollBackLoading = false
        })
    },
  },
  created() {
    this.getVersionInfo()
    // 检查能否同步配置
    // this.checkDevConfig()
  },
}
</script>

<style lang="scss" scoped>
.version-page {
  margin: -20px;
  background-color: #f5f6f7;
  min-height: calc(100vh - 150px);

  .page-top {
    padding: $space $space 1px;
    background-color: #fff;
    border-radius: 0 0 5px 5px;

    .auto-update {
      padding: 2px 12px 20px;

      .switch-btn {
        display: flex;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }

      .update-tip {
        font-size: 12px;
        color: #606266;
        margin-top: 14px;

        .el-icon-info {
          font-size: 15px;
          color: #c0c4cc;
          margin-right: 4px;
        }
      }
    }
  }
  .modular + .modular {
    margin-top: 24px;
  }
  .modular {
    position: relative;
    padding: 24px 20px 16px;
    background-color: #fff;
    border-radius: 5px;

    .none-version {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
      font-size: 12px;
      color: #606266;
    }
    .modular-item {
      padding-bottom: 16px;
      border-bottom: 1px #e9e9e9 solid;
    }
    .modular-item + .modular-item {
      margin-top: 40px;
    }
  }

  .version-detail {
    min-height: calc(100vh - 520px);
  }

  .modular-content {
    display: flex;
    padding: 0 8px;
    .form {
      flex: 0 0 50%;
    }

    .detail-right-btn {
      flex: 0 0 50%;
      display: flex;
      align-items: center;
      padding-bottom: 24px;

      .btn-group {
        margin-left: auto;
        margin-right: 70px;
        width: 216px;
        flex-shrink: 0;
        white-space: nowrap;

        .active-btn {
          background: rgba(53, 118, 255, 0.15);
          color: #3576ff;
        }

        .btn-layout {
          display: flex;
          align-items: center;
        }

        .qr-code-btn {
          width: 12px;
          height: 12px;
          margin-left: 4px;
        }
      }

      .el-button + .el-button {
        margin-left: 24px;
      }
    }
  }

  .form {
    ::v-deep.el-form-item__label,
    ::v-deep.el-form-item__content {
      line-height: 14px;
    }

    .el-form-item {
      margin-bottom: 24px;

      .update-log {
        cursor: pointer;
        font-size: 14px;
        color: #3479ef;
        margin-left: 33px;
      }

      .is-urgent-tip {
        font-size: 12px;
        color: #606266;
        margin-top: 14px;
        margin-left: -82px;

        .el-icon-info {
          color: #c0c4cc;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
