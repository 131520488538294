import api from '@/base/utils/request';

// 获取小程序版本设置信息
export const versionInfo = data => {
  return api({
    url: '/admin/wx_open/wx_applet_version/versionInfo',
    method: 'post',
    data
  });
};

// 自动更新
export const AutoUpdate = data => {
  return api({
    url: '/admin/wx_open/wx_applet_version/changeAutoUpdate',
    method: 'post',
    data
  });
};

// 获取加急次数
export const SpeedupCheck = data => {
  return api({
    url: '/admin/wx_open/wx_applet_version/getSpeedupCheck',
    method: 'post',
    data
  });
};

//版本提交审核
export const commitVersion = data => {
  return api({
    url: '/admin/wx_open/wx_applet_version/commitVersion',
    method: 'post',
    data
  });
};
//检查是否存在可以同步到体验版的配置
export const checkDevPageConfig = data => {
  return api({
    url: '/admin/wx_open/WxAppletVersion/checkDevPageConfig',
    method: 'post',
    data
  });
};
//同步预览版配置到体验版
export const syncDevPageConfig = data => {
  return api({
    url: '/admin/wx_open/WxAppletVersion/syncDevPageConfig',
    method: 'post',
    data
  });
};

// 审核撤回
export const undoCommit = data => {
  return api({
    url: '/admin/wx_open/wx_applet_version/undoCommit',
    method: 'post',
    data
  });
};

// 版本发布
export const versionPost = data => {
  return api({
    url: '/admin/wx_open/wx_applet_version/post',
    method: 'post',
    data
  });
};

// 版本回退
export const rollbackVersion = data => {
  return api({
    url: '/admin/wx_open/wx_applet_version/rollbackVersion',
    method: 'post',
    data
  });
};

// 选为体验版
export const uploadVersion = data => {
  return api({
    url: '/admin/wx_open/wx_applet_version/uploadCode',
    method: 'post',
    data
  });
};