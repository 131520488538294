<template>
  <el-dialog title="体验二维码" :visible.sync="openDialog"
             close-on-click-modal close-on-press-escape
             width="250px" class="dialog-vertical" append-to-body>
    <el-image class="qr-code" :src="qrCode" fit="cover"></el-image>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    qrCode: {
      type: String,
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.qr-code {
  width: 180px;
  height: 180px;
}

.dialog-vertical {
  ::v-deep.el-dialog__body {
    text-align: center;
  }
}
</style>
