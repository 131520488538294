<template>
  <el-dialog title="提交审核" :visible.sync="openDialog" width="667px" class="dialog-vertical"
    @close="cancel('commit-version')">
    <div class="commit-tip" v-if="version">你正在提交开发版本：{{version}}，请选择本次审核是否加急</div>
    <el-form size="medium" :model="_data" label-position="right" label-width="70px" class="small-form"
      ref="commit-version">
      <el-form-item label="审核加急：" prop="ver_code" style="margin-bottom: 0;">
        <el-radio v-model="isUrgent" :label="0">不加急</el-radio>
        <el-radio v-model="isUrgent" :label="1">加急</el-radio>
        <span class="speedup-check" v-if="SpeedupCheck">（一年 {{SpeedupCheck.limit}} 次，今年剩余
          {{SpeedupCheck.rest}} 次）</span>
      </el-form-item>
    </el-form>
    <div class="no-speedup" v-if="!isUrgent">选择不加急审核，预计在1-7天内完成审核。</div>
    <div class="speedup" v-else>
      <p class="speedup-title"><span class="el-icon-info info-icon"></span>加急说明</p>
      <p class="speedup-list">1. 加急审核预计在工作时间 2 小时内完成。工作时间：09:00-19:00。</p>
      <p class="speedup-list">2. 提交加急审核将扣除 1 次加急机会。若加急审核被驳回，在驳回后 12 小时内提交的审核或反馈，将被优先审核。</p>
      <p class="speedup-list">3. 若加急队列拥堵或代码中包含较复杂逻辑可能导致审核时间延长。</p>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" :loading="saveLoading" @click="commit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { SpeedupCheck, commitVersion } from "../api/version-setting.js";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
    },
    version: {
      type: String,
    },
    update: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      SpeedupCheck: null,
      isUrgent: 0,
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getSpeedupCheck();
      }
    },
  },
  methods: {
    // 获取加急次数
    getSpeedupCheck() {
      SpeedupCheck()
        .then((res) => {
          this.SpeedupCheck = res.data;
        })
        .catch((err) => {});
    },
    // 提交审核
    commit() {
      this.saveLoading = true;
      commitVersion({ is_speedup: this.isUrgent, id: this.id })
        .then((res) => {
          this.$message.success(res.msg);
          this.saveLoading = false;
          this.openDialog = false;
          this.update();
        })
        .catch((err) => {
          this.saveLoading = false;
        });
    },
    // 取消
    cancel(formName) {
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.commit-tip {
  font-size: 14px;
  color: #606266;
  line-height: 24px;
  margin-bottom: 26px;
}

.speedup-check {
  margin-left: -20px;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
}

.no-speedup {
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 7px;
}

.speedup {
  margin-top: 7px;
  .speedup-title {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #606266;
    line-height: 12px;
    margin-bottom: 11px;
    .info-icon {
      font-size: 16px;
      margin-right: 8px;
    }
  }
  .speedup-list {
    font-size: 12px;
    color: #606266;
    line-height: 18px;
  }
}
.is-confirm {
  margin-top: 10px;
  ::v-deep.el-checkbox__label {
    font-size: 14px;
  }
}

.form-item {
  display: flex;
  width: 95%;
  justify-content: space-between;
}
</style>
