<template>
  <el-dialog
    title="提示"
    :visible.sync="openDialog"
    width="400px"
    class="dialog-vertical"
    @close="cancel"
    @open="dialogOpen"
  >
    <div v-loading="loading" class="container">
      <div class="title" v-if="isMergeConfig">
        当前当前审核版本：{{ version }}
      </div>
      <div class="title" v-else>
        <i class="el-icon-info"></i>

        是否提交当前开发版本：{{ version }} 进行审核?
      </div>
      <div class="tips" v-if="isMergeConfig">
        <p>
          <i class="el-icon-info"></i>
          页面装修内容未更新，请点击更新
        </p>
        <el-link type="primary" :underline="false" @click="updatePageConfig"
          >点击更新</el-link
        >
      </div>
    </div>
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="submit"
        :loading="saveLoading"
        >提交审核</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  syncDevPageConfig,
  commitVersion,
  checkDevPageConfig,
} from "../api/version-setting.js";

export default {
  components: {},
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {},
    version: {},
  },
  data() {
    return {
      // 保存加载条
      saveLoading: false,
      // 内容加载
      loading: false,
      // 是否有可以同步的配置
      isMergeConfig: 0,
    };
  },
  methods: {
    dialogOpen() {
      // 检查是否存在可以同步到体验版的配置
      this.checkDevConfig();
    },
    // 提交审核
    submit() {
      this.saveLoading = true;
      // console.log(this.$props.id)
      commitVersion({ is_speedup: 0, id: this.$props.id })
        .then((res) => {
          this.$message.success(res.msg);
          this.saveLoading = false;
          this.openDialog = false;
        })
        .catch((err) => {
          this.$message.error("操作失败");
          this.saveLoading = false;
        });
    },
    // 同步预览版配置到体验版
    updatePageConfig() {
      this.loading = true;
      syncDevPageConfig()
        .then((res) => {
          this.$message.success("操作成功");
          // 检查是否存在可以同步到体验版的配置
          this.checkDevConfig();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 取消
    cancel() {
      this.openDialog = false;
    },
    // 检查是否存在可以同步到体验版的配置
    checkDevConfig() {
      this.loading = true;
      checkDevPageConfig().then((res) => {
        this.isMergeConfig = res.data.status;
        this.loading = false;
      });
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
        this.$emit("done", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .title {
    margin-bottom: 10px;
  }

  .tips {
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
      // width: 20px;
    }
    p {
    }

    el-link {
    }
  }
}
</style>
